import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO site={data.site} title="Krzysztof Witczak's Offer" />

        <h1 className="about-header">You can hire me as a consultant!</h1>
        <div className="about-content">
          <div className="about-text">
            <h2>How to contact me?</h2>
            <p>
              I prefer remote consultancy in GMT +/- 4h. Reach me on
              <a href="https://www.linkedin.com/in/witczax/"> linkedin</a> or
              schedule a call on{" "}
              <a href="https://calendly.com/witczax/intro-meeting">calendly</a>.
            </p>

            <h2>How much does it cost?</h2>
            <p>
              I charge 100 GBP/hour and submit invoice on the end of the month
              with SOW. The intro meeting is free.
            </p>

            <h2>What can I help you with?</h2>
            <div className="talks-content">
              <ul>
                <li className="talks-li">
                  <span className="date-field">Fractional CTO service</span> -
                  ideal if you need interim CTO, or technical part-time
                  assistance for a couple of months. Some of the problems I may
                  help you solving:
                  <ul>
                    <li>
                      Problem identification - if its unknown where the problem
                      even is!
                    </li>
                    <li>Technical advisory on startup launch</li>
                    <li>North Star, OKR & KPI frameworks implementation</li>
                    <li>Managing engineering teams and budgets</li>
                    <li>Remote organization setup</li>
                    <li>Identification of engineering vision and strategy</li>
                    <li>Addressing technical debt</li>
                    <li>
                      Implementing performance frameworks like DevEx, DORA or
                      SPACE
                    </li>
                    <li>
                      Improving engineering organisation structure, preparing
                      for scale-up
                    </li>
                    <li>
                      Hiring & onboarding software engineers or improving those
                      processes
                    </li>
                    <li>SDLC optimisation & monitoring</li>
                    <li>
                      Software engineering & managers coaching or mentoring
                    </li>
                    <li>Software engineering career growth plans</li>
                    <li>Establishing healthy engineering culture</li>
                    <li>IT system administration (GSuite, Azure)</li>
                    <li>Designing company career paths and skill scorecards</li>
                    <li>
                      Agile frameworks, retrospective facilitation and continous
                      improvement
                    </li>
                  </ul>
                </li>
                <li className="talks-li">
                  <span className="date-field">
                    Mergers & Acquisitions advisory
                  </span>
                  - ideal if you are before due dilligence and you lack
                  resources or want to stay annonymous.
                  <ul>
                    <li>
                      Technical due dilligence execution (software architecture,
                      quality, security, IT systems)
                    </li>
                    <li>
                      Organizational due dilligence (management, leadership, HR,
                      ISMS)
                    </li>
                    <li>Analysis, documentation & reporting</li>
                    <li>Post merger integration (PMI) planning & execution</li>
                  </ul>
                </li>
                <li className="talks-li">
                  <span className="date-field">
                    ISO 27001 first certification implementation or ISMS
                    improvement
                  </span>
                  - ideal if you want to step up on your path towards secure
                  organization, full scope usually takes 6-12 months.
                  <ul>
                    <li>Gap analysis</li>
                    <li>Risk identification & treatment plan</li>
                    <li>Security monitoring</li>
                    <li>Assets management</li>
                    <li>Vendor and third party management</li>
                    <li>Incident management procedure</li>
                    <li>Implementing secure systems, processes and controls</li>
                    <li>Secure software engineering</li>
                    <li>Business continuity procedures</li>
                    <li>Backup policies</li>
                    <li>
                      Training staff on identified security awareness gaps
                    </li>
                    <li>
                      Preparing necessary ISMS policies and ISO 27001
                      certification documents
                    </li>
                    <li>Contacting with ISO 27001 auditing bodies</li>
                    <li>GDPR/CCPA compliance</li>
                  </ul>
                </li>

                <li className="talks-li">
                  <span className="date-field">
                    Specific request/topic/problem resolution
                  </span>
                  - if you are looking more for a shorter consultancy or you
                  have a specific problem in mind to be solved.
                  <ul>
                    <li>Technical/leadership talks, webinars or trainings</li>
                    <li>
                      Newest industry reports (DORA, Tech Radars, Gartner and
                      other trends)
                    </li>
                    <li>Web applications, SaaS, distributed systems</li>
                    <li>Wardley mapping</li>
                    <li>No-code automation</li>
                    <li>Modern SaaS product discovery</li>
                    <li>ADRs, RFDs</li>
                    <li>Threat modelling exercises</li>
                    <li>Domain Driven Design workshops</li>
                    <li>Gen-AI tooling</li>
                    <li>Buy vs build workshops</li>
                    <li>Change management (rolling out a big change)</li>
                    <li>DevSecFinOps, CI/CD</li>
                    <li>Defining unique value proposition</li>
                    <li>Best practices discussion</li>
                    <li>Any other, specific topic from the entire offer</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        metaTitle
      }
    }
  }
`
